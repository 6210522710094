import GoogleAnalyticsService from "../GoogleAnalyticsService";
import * as Sentry from "@sentry/browser";
import MyRentalsConfig from "@/services/MyRentalsConfig";
import { Environment } from "@/enums/Config";

jest.mock("@/services/MyRentalsConfig");

const ganalytics = new GoogleAnalyticsService();

beforeEach(() => {
  jest.restoreAllMocks();
  spyOn(Sentry, "captureException");

  window.dataLayer = [];
});

describe("GoogleAnalyticsService", () => {
  describe("trackTrigger", () => {
    /* EXAMPLE. REMOVE WHEN REAL TRACKINGS ARE ADDED, OR LEAVE IF NECESSARY */

    it("should call trackTrigger method with correct params", () => {
      // given
      spyOn(console, "info");
      // when
      ganalytics.RNTQualified(123);
      // then
      expect(console.info).toHaveBeenCalledWith(
        `[Google Analytics] TrackObjective RNT_qualified rentals RNT_qualified null 123`
      );
    });
    it("should not console log anything if environment is production", () => {
      spyOn(MyRentalsConfig, "env").and.returnValue({
        ENVIRONMENT: Environment.PROD,
      });
      spyOn(console, "info");
      spyOn(console, "error");

      // when
      ganalytics.RNTQualified(123);
      // then

      expect(console.info).not.toHaveBeenCalled();
      expect(console.error).not.toHaveBeenCalled();
    });
  });
  /*END OF EXAMPLE*/
});
